import {Button as MuiButton, ButtonProps, css, styled} from '@mui/material';
import React, {forwardRef} from 'react';
import {LoadingButton} from "@mui/lab";
import {InputUnstyled, InputUnstyledProps} from "@mui/base";

export const Container = styled('div')(() =>
  css`
    width: calc(100% - 96px);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    column-gap: 15px;
  `)
;

export const FieldSet = styled('fieldset')`
  border: 1px solid black;
  padding: 30px;
`;

export const RootStyle = styled('div')(() =>
    css`
      padding-top: 120px;
      padding-bottom: 80px;
      padding-left: 0px;
      margin: auto;
      width: 40%;
      height: 100%;
    `
  )
;

export const TextInput = forwardRef<HTMLInputElement, InputUnstyledProps>(({ children, ...rest }, ref) => (
  <InputUnstyled
    ref={ref}
    css={{
      '& .MuiInput-input': {
        textAlignVertical: 'top',
        display: 'block',
        height: '38px',
        padding: '8px 12px',
        resize: 'none',
        outline: '0',
        width: '100%',
        fontSize: 'inherit',
        color: 'inherit',
        backgroundColor: 'transparent',
        transition: 'background-color 0.2s ease',
        boxShadow: 'none',
        border: 1,
      },
      '&:hover': {
        transition: 'background-color 0.2s ease 0s, box-shadow 0.2s ease 0s',
        boxShadow: 'rgb(100 168 255) 0px -2px 0px 0px inset',
        backgroundColor: 'rgb(249, 250, 251)',
      },

      '&:focus': {
        transition: 'background-color 0.2s ease 0s, box-shadow 0.2s ease 0s',
        boxShzadow: 'rgb(100 168 255) 0px -2px 0px 0px inset',
        backgroundColor: 'rgb(249, 250, 251)',
      },
    }}
    {...rest}
  >
    {children}
  </InputUnstyled>
));

export const Button = ({children, ...rest}: ButtonProps) => {
  return (
    <MuiButton
      sx={{
        // display: 'flex',
        // flexDirection: 'column',
        // gap: '0.1rem',
        borderRadius: '5px',
        height: '40px',
        width: '200px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#f9fafb',
        backgroundColor: '#3182f6',
        '&:hover': {backgroundColor: '#1b64da'},
        lineHeight: 1.25,
        justifyContent: 'center',
        alignItems: 'center',
        align: 'center',
        textAlign: 'center'
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};