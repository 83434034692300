import React, {ReactElement, useCallback, useState} from 'react';
import * as S from './index.styles';
import Layout from '@components/Layout/Layout';
import {Button} from '@mui/material';
import axios from 'axios';
import MemberAuthApi from '@apis/MemberAuthApi/MemberAuthApi';
import {useRecoilState, useRecoilValue} from 'recoil';
import userAtom, {useUserReducer} from '@recoil/user';
import {MemberSignUpRequest} from '@apis/MemberAuthApi/MemberAuthApi.types';
import {useRouter} from "next/router";

const LoginPage = () => {
  const router = useRouter();
  const [user, setUser] = useRecoilState(userAtom);
  const [inputId, setInputId] = useState("")
  const [inputPassword, setInputPassword] = useState("")
  const {isInitialized, isAuthenticated} = useRecoilValue(userAtom);

  if (isAuthenticated) {
    router.push('/main');
  }

  const {authorize, unauthorize} = useUserReducer();

  const login = useCallback(async (loginId: string, password: string) => {
    const {data} = await MemberAuthApi.login({loginId, password});
    authorize(data);
  }, []);

  const handleLogin = async () => {
    try {
      await login(inputId, inputPassword);
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        alert(e?.response?.data.message);
      }
    }
  };

  const handleMe = async () => {
    try {
      await MemberAuthApi.me(user?.accessToken);
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        alert(e?.response?.data.message);
      }
    }
  };

  return (
    <S.RootStyle>
      <S.Container>
        <S.FieldSet>
          <legend style={{fontWeight: '700'}}>로그인</legend>
          <div>{"아이디"}</div>
          <S.TextInput
            value={inputId}
            onChange={(e) => setInputId(e.target.value)}
          />
          <div>{"비밀번호"}</div>
          <S.TextInput
            type="password"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
          />
          <S.Button onClick={handleLogin}>로그인</S.Button>
        </S.FieldSet>
      </S.Container>
    </S.RootStyle>
  )
};

export default LoginPage;

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};